import React, { useCallback } from 'react';
import { FormikControl } from '..';
import { hardTrim } from '../../../utils';

// For Consultation Forms Only
export function UserInputs({ formik }) {
    // write a custom onChange handler for phone mask to automatically check the "DoNotCall" and "mercury__SMS_Opt_Out__c" box when user starts typing
    const handleOnChangePhoneMask = useCallback(
        (e) => {
            formik?.handleChange(e); // Formik's default onChange
            const phoneNumber = e?.target.value;
            // If user starts typing, automatically check the "DoNotCall" and "mercury__SMS_Opt_Out__c" box
            formik?.setFieldValue('DoNotCall', phoneNumber.length > 3); // +1 is added by default, which adds length 3 by default on focusing the field.
            formik?.setFieldValue('mercury__SMS_Opt_Out__c', phoneNumber.length > 3);
        },
        [formik],
    );
    return (
        <div className="row form-steps-container position-relative overflow-hidden">
            <div className="col form-inputs-wrapper">
                <div className="row gx-2 gx-md-4 justify-content-center">
                    <div className="col-6 col-xl-5 mt-2 mt-md-4 pt-md-1">
                        <FormikControl control="input" name="first_name" placeholder="First Name*" type="text" />
                    </div>
                    <div className="col-6 col-xl-5 mt-2 mt-md-4 pt-md-1">
                        <FormikControl control="input" name="last_name" placeholder="Last Name*" type="text" />
                    </div>
                </div>
                <div id="scrollToMessage" className="row justify-content-center mb-3 mb-md-4">
                    <div className="col-md-6 col-xl-5 mt-2 mt-md-4 pt-md-1">
                        <FormikControl
                            control="phoneInput"
                            name="phoneMask"
                            placeholder="Phone Number*"
                            type="tel"
                            onChange={(e) => handleOnChangePhoneMask(e)}
                        />
                        <input type="hidden" name="phone" value={`+${hardTrim(formik.values.phoneMask)}`} />
                    </div>
                    <div className="col-md-6 col-xl-5 mt-2 mt-md-4 pt-md-1">
                        <FormikControl control="emailInput" name="email" placeholder="Your Email*" />
                    </div>
                </div>
                <div className="row justify-content-center overflow-hidden pt-md-1">
                    <div
                        id="msg_area"
                        className={`col-xl-10 mb-4 ${formik.status === 'Question Initiated' ? 'slide-down' : ''}`}
                    >
                        <FormikControl control="textarea" name="description" placeholder="Message..." rows="3" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserInputs;
