import React from 'react';
import { FormikControl } from '..';

export function ConsultCheckbox() {
    return (
        <div className="row justify-content-center my-3">
            <div className="col-xl-10 d-flex flex-column custom-checkbox">
                {/* <FormikControl control="consentCheckbox" name="updates" mailchimpID="mailchimp-consult" /> */}
                <div className="pb-3">
                    <FormikControl
                        control="consentCheckboxPhone"
                        name="DoNotCall"
                        mailchimpID="mailchimp-consult-phone"
                    />
                </div>
                <FormikControl
                    control="consentCheckboxSMS"
                    name="mercury__SMS_Opt_Out__c"
                    mailchimpID="mailchimp-consult-sms"
                />
            </div>
        </div>
    );
}

export default ConsultCheckbox;
