import React from 'react';
import { Field } from 'formik';
import { Link } from 'gatsby';

// export function ConsentCheckbox(props) {
//   const { label, name, mailchimpID, ...rest } = props
//   return (
//     <Field name={name}>
//       {
//         props => {
//           const { field } = props
//           return (
//             <label htmlFor={mailchimpID}>
//               By checking this box, you agree to Milan Laser Hair Removal to reach you by phone or text with information about our services, Msg & Data Rates May Apply. Reply Stop to cancel. View our <Link to="/privacy/" className="milan-text-navy text-decoration-underline">Privacy Policy</Link>.
//               <input
//                 type="checkbox"
//                 {...field} {...rest}
//                 value={field.value}
//                 id={mailchimpID}
//                 checked={field.value}/>
//               <span className="checkmark"></span>
//             </label>
//           )
//         }
//       }
//     </Field>
//   )
// }

const getSelectedMethod = (variant) => {
    switch (variant) {
        case 'phone':
            return 'phone';
        case 'sms':
            return 'text';
        default:
            return 'phone or text';
    }
};

export function ConsentCheckbox(props) {
    const { label, name, mailchimpID, variant, ...rest } = props;
    return (
        <Field name={name}>
            {(props) => {
                const { field } = props;
                return (
                    <label htmlFor={mailchimpID}>
                        By checking this box, you agree to Milan Laser Hair Removal to reach you by{' '}
                        {getSelectedMethod(variant)} with information about our services, Msg frequency may vary, Msg &
                        Data Rates May Apply. Reply Stop to cancel. View our{' '}
                        <Link to="/privacy/" className="milan-text-navy text-decoration-underline">
                            Privacy Policy
                        </Link>{' '}
                        &{' '}
                        <Link to="/terms/" className="milan-text-navy text-decoration-underline">
                            Terms and Conditions
                        </Link>{' '}
                        .
                        <input
                            type="checkbox"
                            {...field}
                            {...rest}
                            value={field.value}
                            id={mailchimpID}
                            checked={field.value}
                        />
                        <span className="checkmark"></span>
                    </label>
                );
            }}
        </Field>
    );
}

export default ConsentCheckbox;
