import React from 'react';
import {
    TextArea,
    Input,
    PhoneInput,
    EmailInput,
    Subscription,
    SelectLocation,
    AreasCheckboxes,
    ConsentCheckbox,
    ConsultRadio,
    RadioBtn,
    CheckboxBtns,
    RangeSliderInput,
} from '.';

export function FormikControl(props) {
    const { control, ...rest } = props;
    switch (control) {
        case 'input':
            return <Input {...rest} />;
        case 'phoneInput':
            return <PhoneInput {...rest} />;
        case 'emailInput':
            return <EmailInput {...rest} />;
        case 'textarea':
            return <TextArea {...rest} />;
        case 'mailchimp':
            return <Subscription {...rest} />;
        case 'consentCheckboxPhone':
            return <ConsentCheckbox variant={'phone'} {...rest} />;
        case 'consentCheckboxSMS':
            return <ConsentCheckbox variant={'sms'} {...rest} />;
        case 'consultRadio':
            return <ConsultRadio {...rest} />;
        case 'radioBtn':
            return <RadioBtn {...rest} />;
        case 'checkboxBtns':
            return <CheckboxBtns {...rest} />;
        case 'rangeSlider':
            return <RangeSliderInput {...rest} />;
        case 'selectLocation':
            return <SelectLocation {...rest} />;
        case 'areasCheckboxes':
            return <AreasCheckboxes {...rest} />;
        default:
            return null;
    }
}

export default FormikControl;
